import React from 'react';
import Response from '../../../interfaces/Response';

interface props {
  points: number;
  responses: Response[];
}

const ResultModal: React.FC<props> = ({ points, responses }) => {
  return (
    <div className="w-full bg-white/95 backdrop-blur-sm rounded-xl p-8 shadow-xl">
      <div className="flex justify-between mb-6">
        <span className="text-xl font-bold">Points: {points}</span>
        <button
          // onClick={() => setIsPlaying(false)}
          className="text-red-600 hover:text-red-700 transition-colors"
        >
          You are champion!
        </button>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {responses.length > 0 &&
          responses.map((item: Response, index: number) => (
            <div
              key={index}
              className={`flex flex-col font-semibold text-lg mb-4 ${
                responses.length % 2 !== 0 && index === responses.length - 1 ? 'col-span-2 text-center' : ''
              }`}
            >
              <span>{item.word}</span>
              <span className={`${item.isCorrect ? 'text-green-600' : 'text-red-600'}`}>
                {item.isCorrect ? 'Correct' : 'Incorrect'}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ResultModal;
