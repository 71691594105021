import React, { useCallback, useState } from 'react';
import QuestionPopup from './Questions';
import FinishPage from './Finish';
import { useNavigate } from 'react-router-dom';
import ScrambleModal from './ScrambleModal';
import Response from '../../../interfaces/Response';
import ICard from '../../../interfaces/ICard';
import { Button, notification, Spin } from 'antd';
import { createUserData } from '../../../api/user';
import { LoadingOutlined } from '@ant-design/icons';

const GameCards: React.FC<{ email: string; setIsOpenHowToPlayModal: any }> = ({ email, setIsOpenHowToPlayModal }) => {
  const christmasIcons: ICard[] = [
    {
      id: 1,
      name: 'Christmas Tree',
      image: 'Tree.png',
      question: 'A  Written  Promise (like Santa’s List) to Protect You.',
      answer: 'POLICY',
      hint: 'I am an insurance _ _ _ _ _',
    },
    {
      id: 2,
      name: 'Reindeer',
      image: 'horse.png',
      question: 'Imagine Santa Evaluating his Nice-Naughty List for Risks & Gaps.',
      answer: 'UNDERWRITING',
      hint: 'I help determine the insurance coverage and pricing',
    },
    {
      id: 3,
      name: 'Christmas Ball',
      image: 'BombPreview.png',
      question: 'The Price You Pay to Keep Your Christmas Gift Safe, Like Leaving Cookies for Santa.',
      answer: 'PREMIUM',
      hint: 'I am an the payment for an insurance _____policy.',
    },
    {
      id: 4,
      name: 'Stocking',
      image: 'sockspreview.png',
      question: 'Stuffing a Bonus Stocking When You Want Extra Security in Your Insurance.',
      answer: 'RIDER',
      hint: 'I am the extra cover added to ensure further protection.',
    },
    {
      id: 5,
      name: 'Santa',
      image: 'Santa.png',
      question: ' Where  Does Santa’s Retirement Fund Come From?',
      answer: 'ANNUITY',
      hint: 'I a’m similar to a pension plan.',
    },
    {
      id: 6,
      name: 'Wreath',
      image: 'Garland.png',
      question: 'Santa’s Fine Print Elf that Keeps Track of Those Nice & Naughty Lists!',
      answer: 'CLAUSE',
      hint: 'I am a part of legal documents with all the jargons and conditions.',
    },
    {
      id: 7,
      name: 'Candy Cane',
      image: 'Stick.png',
      question: 'Just Like a Little Toddler  Waiting for Santa’s Surprise Policy Gift.',
      answer: 'CLAIMANT',
      hint: 'I’m the person who eagerly waits to reap  from the policy benefits.',
    },
    {
      id: 8,
      name: 'Bells',
      image: 'Bells.png',
      question: 'It Feels like Unwrapping Your Present on the Christmas Morning.',
      answer: 'PAYOUT',
      hint: 'I am the amount you received when a your claim is approved.',
    },
    {
      id: 9,
      name: 'Gift',
      image: 'Giftpreview.png',
      question: ' When Santa’s Reindeers Demand Regular Maintenance with Cookies & Christmas Treats.',
      answer: 'SERVICING',
      hint: 'My job is to listen to my customers and keep them happy.',
    },
  ];

  const [points, setPoints] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selCard, setSelCard] = useState<ICard | null>(null);
  const [isFinish, setIsFinish] = useState(false);
  const [reseponses, setResponses] = useState<Response[]>([]);
  const [loading, setLoading] = useState(false);

  const handleClosePopup = () => setIsPopupOpen(false);

  const navigate = useNavigate();
  const handleSubmit = () => {
    alert('Answer submitted!');
    setIsPopupOpen(false);
  };

  // const handleFinish = () => {
  //   navigate('/finish');
  // };

  // const isIn = useCallback(
  //   () => {
  //     const ind = reseponses.findIndex(item => item.word === )
  //     if (reseponses)
  //   },
  //   [reseponses],
  // )

  const isCorrect = (val: string) => {
    const item = reseponses.find(item => item.word === val);
    if (item) {
      if (item.isCorrect || item.isTimeout) {
        return true;
      }
    }
    return false;
  };
  const whiteSpinnerIcon = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />;

  const handleCorrect = async () => {
    setLoading(true);
    if (reseponses.length >= 6) {
      const payload = {
        email,
        qustAnswered: reseponses,
        points,
        isGamePage: true,
      };

      const response = await createUserData(payload);
      if (response?.status === 200) {
        setIsFinish(true);
        setLoading(false);
      }
    } else {
      setLoading(false);

      notification.error({
        message: 'please answer atleat 6 questions',
      });
    }
  };

  return (
    <>
      {isFinish ? (
        <FinishPage setIsFinish={setIsFinish} responses={reseponses} points={points} />
      ) : (
        <div className="container ps-2">
          <h2 className="heading font-bold p-2 leading-snug tracking-wide text-orange-400 text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
            Jingle Jumble
          </h2>

          <div className="flex flex-col md:flex-row w-full h-full gap-6 sm:p-6 lg:p-12">
            <div className="flex-1 row grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {christmasIcons.map(item => {
                const src = `${process.env.PUBLIC_URL}/assets/${item.image}`;
                const isItemCorrect = isCorrect(item.answer);
                return (
                  <div
                    key={item.id}
                    className={` card w-full max-w-xs flex items-center justify-center rounded-3xl shadow-[0_4px_10px_rgba(0,0,0,0.2),0_10px_20px_rgba(0,0,0,0.15)] hover:shadow-[0_6px_15px_rgba(0,0,0,0.3)] transition-shadow cursor-pointer overflow-hidden aspect-video hover:border-4 hover:border-yellow-400 questionCard-body ${isCorrect(item.answer) ? 'bg-gray-400' : 'questionCard'}`}
                    onClick={() => {
                      if (!isItemCorrect) {
                        setIsPopupOpen(true);
                        setSelCard(item);
                      }
                    }}
                  >
                    <img src={src} alt={item.name} className="h-20 w-auto object-contain rounded-5" />
                  </div>
                );
              })}
            </div>

            {/* Right Side: Points and Buttons */}
            <div className="w-full md:w-64 lg:w-72 lg:ml-auto space-y-4">
              <div className="flex justify-center items-center mb-4">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/company-logo.png`}
                  className="company-logo img-fluid  md:w-1/3 lg:w-1/2"
                  alt="Newgen"
                />
              </div>
              <button
                className="w-auto BorderandBG font-bold text-lg py-2 px-4 rounded-lg hover:bg-red-50 transition-colors"
                onClick={() => setIsOpenHowToPlayModal(true)}
              >
                How to Play?
              </button>

              <div className="gap-2">
                <button
                  className="w-auto BorderandBG font-bold text-lg py-2 px-4 rounded-lg hover:bg-red-50 transition-colors"
                  //onClick={() => navigate('how-to-play')}
                >
                  Points
                </button>
                <button
                  className="w-auto BorderandBG font-bold text-lg py-2 mx-2 px-4 rounded-lg hover:bg-red-50 transition-colors"
                  //onClick={() => navigate('how-to-play')}
                >
                  {points}
                </button>
              </div>

              <button
                className={`w-auto BorderandBG font-bold text-lg py-2 px-4 rounded-lg hover:bg-red-50 transition-colors ${reseponses.length < 6 ? 'bg-white' : 'bg-green-600'}`}
                onClick={handleCorrect}
                // disabled={reseponses.length < 6}
              >
                {loading ? <Spin indicator={whiteSpinnerIcon} tip="Loading..." /> : 'Finish'}
              </button>

              {isPlaying && (
                <button
                  className="w-75 border-2 border-green-600 text-green-600 py-2 px-4 rounded-md hover:bg-red-50 transition-colors"
                  onClick={() => navigate('how-to-play')}
                >
                  See Result
                </button>
              )}

              {/* <div className="flex justify-center mt-6">
              <img
                src={`${process.env.PUBLIC_URL}/assets/tree-bg.png`}
                className="company-logo img-fluid w-full max-w-xs"
                alt="Tree Image"
              />
            </div> */}
            </div>

            <ScrambleModal
              open={isPopupOpen}
              setOpen={setIsPopupOpen}
              setPoints={setPoints}
              setIsFinish={setIsFinish}
              responses={reseponses}
              setResponses={setResponses}
              card={selCard}
              isCorrected={isCorrect}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GameCards;
