import React from 'react';
import FloatingSparkle from './FloatingSparkle';
import ChristmasBulb from './ChristmasBulb';

const BgLayout: React.FC<{ children: React.ReactNode; bgNum: string }> = ({ children, bgNum }) => {
  return (
    <div
      className="relative min-h-screen flex items-center justify-center p-6 overflow-hidden bg-cover bg-center"
      style={{
        backgroundImage:
          bgNum === '1'
            ? `url(${process.env.PUBLIC_URL}/assets/cropedbg.jpg)`
            : `url(${process.env.PUBLIC_URL}/assets/newbg.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Overlay to ensure text readability */}
      {/* <div className="absolute inset-0 bg-red-900/40 backdrop-blur-sm" /> */}

      {/* Animated sparkles */}
      {/* <div className="absolute inset-0 overflow-hidden">
        {Array.from({ length: 30 }).map((_, i) => (
          <FloatingSparkle key={i} size={25 + Math.random() * 8} />
        ))}
      </div> */}

      {/* Christmas Bulbs */}
      {/* <div className="absolute top-0 left-0 w-full flex justify-around mt-0">
        {Array.from({ length: 10 }).map((_, index) => (
          <ChristmasBulb key={index} />
        ))}
      </div> */}

      {/* Snow effect */}
      {/* <div className="absolute inset-0 pointer-events-none">
        {Array.from({ length: 50 }).map((_, i) => (
          <div
            key={`snow-${i}`}
            className="absolute animate-fall text-white/80"
            style={{
              left: `${Math.random() * 100}%`,
              top: `-${Math.random() * 20}%`,
              opacity: 0.4 + Math.random() * 0.6,
              animation: `fall ${5 + Math.random() * 10}s linear infinite`,
              animationDelay: `-${Math.random() * 10}s`,
            }}
          >
            ❄
          </div>
        ))}
      </div> */}

      <div className="relative z-10 max-w-screen w-full">{children}</div>
    </div>
  );
};

export default BgLayout;
