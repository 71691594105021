import { useNavigate } from 'react-router-dom';

const HowToPlay: React.FC<{ showDashboard: () => void }> = ({ showDashboard }) => {
  const navigate = useNavigate();
  return (
    <div
      className="relative min-h-screen w-full bg-white !m-0 !p-0"
      style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
    >
      {/* Main container for image and button */}
      <div
        className="relative h-screen w-full flex items-center justify-center !m-0 !px-4 md:!px-8"
        style={{ minHeight: '100vh', maxWidth: '100vw', overflow: 'hidden' }}
      >
        {/* Background image container */}
        <div
          className="w-full h-full bg-contain bg-no-repeat bg-center !m-0 !p-0"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/Scroll.png)`,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        />

        <button
          className="bg-orange-400 text-white px-8 py-4 md:px-10 md:py-5 rounded-full font-bold text-lg md:text-xl animate-bounce hover:bg-orange-600 transition-all !absolute !bottom-5 !right-5 md:!bottom-10 md:!right-10"
          onClick={() => showDashboard()}
          style={{ zIndex: 2 }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default HowToPlay;
