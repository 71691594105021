import React, { useCallback, useRef, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import BgLayout from '../../../components/Layout';
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from 'lucide-react';
import Response from '../../../interfaces/Response';
import ResultModal from './Result';
import { useNavigate } from 'react-router-dom';

interface props {
  setIsFinish: React.Dispatch<React.SetStateAction<boolean>>;
  responses: Response[];
  points: number;
}

const FinishPage: React.FC<props> = ({ setIsFinish, responses, points }) => {
  const [runConfetti, setRunConfetti] = useState(false);
  const [seeResult, setSeeResult] = useState(false);
  const confettiRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const fireConfetti = useCallback(() => {
    setRunConfetti(true);
  }, []);

  useEffect(() => {
    fireConfetti();
  }, [fireConfetti]);

  // Set the confetti effect to stop after some time (e.g., 3 seconds)
  useEffect(() => {
    if (runConfetti) {
      const timer = setTimeout(() => {
        setRunConfetti(false);
      }, 5000); // Adjust duration as needed

      return () => clearTimeout(timer);
    }
  }, [runConfetti]);

  const handleFinish = () => {};

  return (
    <div className="relative flex items-center justify-center">
      <div className="flex flex-col items-center space-y-4 text-center">
        <h1 className="text-4xl md:text-6xl font-bold text-orange-400 animate-bounce">Congratulations!</h1>
        <p className="text-lg md:text-2xl text-orange-400 animate-pulse">
          While Newgen Santa gets your surprise ready, explore our innovative insurance solutions!
        </p>
        <div className="w-4/5 mt-24 flex flex-col items-center text-lg md:text-2xl text-white animate-bounce">
          <div
            className="cursor-pointer flex items-center gap-1 text-orange-400 mt-10"
            onClick={() => (window.location.href = 'https://newgensoft.com/solutions/industries/insurance-in/')}
          >
            <ArrowLeftCircleIcon size={20} className="text-orange-400" />
            <span className="text-center">Know More</span>
          </div>
        </div>

        {seeResult && <ResultModal points={points} responses={responses} />}
      </div>

      {/* Confetti Effect */}
      {runConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={150} // You can customize this value
        />
      )}
    </div>
  );
};

export default FinishPage;
