import { IQandAArr } from '../interfaces/IQA';

export const christmasQuestionsArr: IQandAArr = {
  low: [
    { question: 'What brings smiles and laughter?', answer: 'JOY' },
    { question: 'What blankets the town in white?', answer: 'SNOW' },
    { question: 'What decorates every home?', answer: 'TREE' },
    { question: 'What guides the way to celebration?', answer: 'STAR' },
    { question: 'What do we wrap and give with care?', answer: 'GIFT' },
    { question: 'What’s shared with family and friends?', answer: 'LOVE' },
    { question: 'What reminds us of winter’s chill?', answer: 'COLD' },
    { question: 'Who helps Santa year-round?', answer: 'ELF' },
    { question: 'What chimes in the holiday spirit?', answer: 'BELL' },
    { question: 'Who’s jolly and loves the cold?', answer: 'SNOWMAN' },
    { question: 'What’s sweet and pepperminty?', answer: 'CANDY' },
    { question: 'What brightens the holiday night?', answer: 'LIGHT' },
    { question: 'Who watches over us from above?', answer: 'ANGEL' },
    { question: 'What season is full of magic?', answer: 'WINTER' },
    { question: 'What plant symbolizes holiday cheer?', answer: 'HOLLY' },
    { question: 'Calm and quiet feelings?', answer: 'PEACE' },
    { question: 'Warm holiday greetings?', answer: 'TIDINGS' },
    { question: 'Lights and laughter describe?', answer: 'FESTIVE' },
  ],
  medium: [
    { question: 'Who pulls Santa’s sleigh?', answer: 'REINDEER' },
    { question: 'What hangs on doors in winter?', answer: 'WREATH' },
    { question: 'What brings holiday kisses?', answer: 'MISTLETOE' },
    { question: 'What cookie is crafted with care?', answer: 'GINGERBREAD' },
    { question: 'What sparkles on the tree?', answer: 'ORNAMENT' },
    { question: 'What ties up presents with style?', answer: 'RIBBON' },
    { question: 'What does Santa come down?', answer: 'CHIMNEY' },
    { question: 'What decorates our mantles?', answer: 'GARLAND' },
    { question: 'What’s Jack Frost known for?', answer: 'FROSTY' },
    { question: 'What cone comes from pine trees?', answer: 'PINECONE' },
    { question: 'What carries Santa through the night?', answer: 'SLEIGH' },
    { question: 'Who’s a bit green and grumpy?', answer: 'GRINCH' },
    { question: 'What do we give to our loved ones?', answer: 'PRESENTS' },
    { question: 'Which animal leads the sleigh?', answer: 'REINDEER' },
    { question: 'A welcoming ring on the door?', answer: 'WREATH' },
  ],
  hard: [
    { question: 'What’s celebrated on December 25th?', answer: 'CHRISTMAS' },
    { question: 'What’s the story of Jesus’s birth?', answer: 'NATIVITY' },
    { question: 'What brings people together in joy?', answer: 'CELEBRATION' },
    { question: 'What’s the spirit of coming together?', answer: 'TOGETHERNESS' },
    { question: 'What do we cherish during the holidays?', answer: 'FAMILY' },
    { question: 'What intricate design falls from the sky?', answer: 'SNOWFLAKE' },
    { question: 'Who fills stockings on Christmas Eve?', answer: 'SAINTNICHOLAS' },
    { question: 'What service happens at midnight on Christmas?', answer: 'MIDNIGHTMASS' },
    { question: 'What brings a warm glow to Christmas night?', answer: 'CANDLELIGHT' },
    { question: 'Where does Santa live?', answer: 'NORTHPOLE' },
    { question: 'What’s the final month of the year?', answer: 'DECEMBER' },
    { question: 'What fills the air with happiness?', answer: 'HOLIDAYCHEER' },
    { question: 'What dense dessert is a holiday staple?', answer: 'FRUITCAKE' },
    { question: 'What is the spirit of kindness and sharing?', answer: 'GOODWILL' },
    { question: 'What’s hung by the chimney with care?', answer: 'STOCKING' },
    { question: 'A December holiday?', answer: 'CHRISTMAS' },
    { question: 'A place in the north?', answer: 'NORTHPOLE' },
  ],
};
