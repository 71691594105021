import React, { useEffect, useState } from 'react';
import { IQandA } from '../../../interfaces/IQA';
import { christmasQuestionsArr } from '../../../constants/words';
import { Modal, notification } from 'antd';
import Response from '../../../interfaces/Response';
import './ScrambleModal.css';
import correctAnswerPhrases from '../../../constants/correctAnswerPhrases';
import ICard from '../../../interfaces/ICard';

interface LetterTile {
  letter: string;
  id: number;
}

interface props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPoints: React.Dispatch<React.SetStateAction<number>>;
  setIsFinish: React.Dispatch<React.SetStateAction<boolean>>;
  responses: Response[];
  setResponses: React.Dispatch<React.SetStateAction<Response[]>>;
  card: ICard | null;
  isCorrected: any;
}

const ScrambleModal: React.FC<props> = ({
  open,
  setOpen,
  setPoints,
  setIsFinish,
  responses,
  setResponses,
  card,
  isCorrected,
}) => {
  const timeDef = 45;
  const [scrambledLetters, setScrambledLetters] = useState<LetterTile[]>([]);
  const [selectedLetters, setSelectedLetters] = useState<LetterTile[]>([]);
  const [time, setTime] = useState(timeDef);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [currentQuestion, setCurrentQuestion] = useState('');

  useEffect(() => {
    startGame();
  }, [card?.answer]);

  const startGame = () => {
    setTime(timeDef);
    selectNewWord();
  };

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);

      // Cleanup interval on unmount or dependency change
      return () => clearInterval(timerId);
    } else if (time === 0) {
      setResponses([
        ...responses,
        {
          word: card?.answer ?? '',
          isCorrect: false,
          isTimeout: true,
        },
      ]);
      setOpen(false);
    }
  }, [time]);

  const selectNewWord = () => {
    setTime(timeDef);
    setCurrentQuestion(card?.question || '');
    setScrambledLetters(
      (card?.answer || '')
        .split('')
        .map((letter: string, index: number) => ({
          letter,
          id: index,
        }))
        .sort(() => Math.random() - 0.5)
    );
    setSelectedLetters([]);
  };

  const showRandomSuccessMessage = () => {
    const randomIndex = Math.floor(Math.random() * correctAnswerPhrases.length);
    notification.success({
      message: correctAnswerPhrases[randomIndex],
    });
  };

  const checkAnswer = () => {
    if (selectedLetters.map(l => l.letter).join('') === card?.answer) {
      setPoints(prev => prev + 2);
      setIsCorrect(true);
      setResponses([
        ...responses,
        {
          word: card?.answer,
          isCorrect: true,
          isTimeout: false,
        },
      ]);
      showRandomSuccessMessage();
      setOpen(false);
    } else {
      // setIsCorrect(false);
      // setResponses([
      //   ...responses,
      //   {
      //     word: card?.answer || '',
      //     isCorrect: false,
      //     isTimeout: false,
      //   },
      // ]);
      //setTime(timeDef);
      notification.error({ message: 'please try again!' });
    }
    // selectNewWord();
  };

  const body = () => {
    return (
      <div className="bg-white/95 backdrop-blur-sm pb-3">
        <div className="flex justify-center w-full">
          <div className="w-full sm:text-center mb-2 sm:mb-0">
            <div
              className={`flex items-center justify-center font-semibold ${time > 5 ? 'text-green-600 animate-pulse' : 'text-danger'} transition-all`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/stopwatch.png`}
                className="img-fluid mx-2"
                style={{ width: '8%' }}
                alt="Stopwatch"
              />
              <span className="fs-5">00:{time}</span>
            </div>
          </div>

          <div className="bg-white/95 backdrop-blur-sm pb-3 sm:mt-0">
            <div className="hover-text w-full text-center sm:text-end">
              <span className="bg-orange text-light px-2 rounded-5" style={{ fontSize: '1.2rem' }}>
                {' '}
                i{' '}
              </span>
              <span className="tooltip-text pop-left">{card?.hint}</span>
            </div>
          </div>
        </div>

        <div className="bg-orange border border-black flex font-semibold justify-center mb-4 p-2 rounded-2 text-lg text-light">
          {currentQuestion}
        </div>

        <div className="flex justify-center m-0 p-0">
          <div className="d-block gap-2 mb-6 w-auto">
            {scrambledLetters.map(letterObj => (
              <button
                key={letterObj.id}
                onClick={() => {
                  if (!selectedLetters.some(sel => sel.id === letterObj.id)) {
                    setSelectedLetters([...selectedLetters, letterObj]);
                  }
                }}
                className="text-white text-latter-style m-1 rounded-lg transform transition-all hover:scale-110 active:scale-95 disabled:opacity-50 hover:shadow-lg disabled:hover:scale-100 min-w-[25px] sm:min-w-[30px]"
                disabled={selectedLetters.some(sel => sel.id === letterObj.id)}
              >
                {letterObj.letter}
              </button>
            ))}
          </div>
        </div>

        <div className="d-flex flex-lg-wrap mb-6 w-auto">
          {selectedLetters.map(letterObj => (
            <div
              key={letterObj.id}
              className="bg-gray-200 text-gray-800 text-center font-bold m-1 rounded-lg  min-w-[25px] sm:min-w-[30px]  animate-pop-in mb-2"
            >
              {letterObj.letter}
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-between gap-2">
          <button onClick={() => setSelectedLetters([])} className="btn w-50 border bg-gray-400 text-light">
            Clear
          </button>
          <button onClick={checkAnswer} className="btn w-50 bg-orange text-light">
            Submit
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      closeIcon={null}
      onCancel={() => setOpen(false)}
      footer={null}
      className="transition-all duration-1000 ease-in-out"
    >
      {body()}
    </Modal>
  );
};

export default ScrambleModal;
