import React, { useState, useEffect } from 'react';
import BgLayout from '../../../components/Layout';
import { christmasQuestionsArr } from '../../../constants/words';
import { IQandA } from '../../../interfaces/IQA';
import EmailComp from './Email';
import GameCards from './Cards';
import { Modal } from 'antd';
// import HomePageBG from '../../../../public/assets/home-page-bg.jpg';
interface LetterTile {
  letter: string;
  id: number;
}

interface Response {
  word: string;
  isCorrect: boolean;
}

const Dash: React.FC = () => {
  const timeDef = 20;
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEmailModalOpened, setIsEmailModalOpened] = useState(false);
  const [bgNum, setBgNum] = useState('1');
  const [points, setPoints] = useState(0);
  const [currentWord, setCurrentWord] = useState('');
  const [scrambledLetters, setScrambledLetters] = useState<LetterTile[]>([]);
  const [selectedLetters, setSelectedLetters] = useState<LetterTile[]>([]);
  const [time, setTime] = useState(timeDef);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [reseponses, setResponses] = useState<Response[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [IsOpenHowToPlayModal, setIsOpenHowToPlayModal] = useState(false);
  const [email, setGlobalEmail] = useState('');

  const christmasWords: IQandA[] = christmasQuestionsArr.low;

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);

      // Clear the interval when the component unmounts or time hits 0
      return () => clearInterval(timerId);
    } else {
      selectNewWord();
      setTime(timeDef);
    }
  }, [time]);

  const startGame = () => {
    setIsPlaying(true);
    setTime(timeDef);
    selectNewWord();
  };

  const selectNewWord = () => {
    setTime(timeDef);
    const word = christmasWords[Math.floor(Math.random() * christmasWords.length)];
    setCurrentQuestion(word.question);
    setCurrentWord(word.answer);
    setScrambledLetters(
      word.answer
        .split('')
        .map((letter: string, index: number) => ({
          letter,
          id: index,
        }))
        .sort(() => Math.random() - 0.5)
    );
    setSelectedLetters([]);
  };

  const checkAnswer = () => {
    if (selectedLetters.map(l => l.letter).join('') === currentWord) {
      setPoints(prev => prev + 10);
      setIsCorrect(true);
      setResponses([
        ...reseponses,
        {
          word: currentWord,
          isCorrect: true,
        },
      ]);
    } else {
      setIsCorrect(false);
      setResponses([
        ...reseponses,
        {
          word: currentWord,
          isCorrect: false,
        },
      ]);
    }
    selectNewWord();
  };

  const showDashboard = () => {
    setBgNum('2');
  };

  return (
    <BgLayout bgNum={bgNum}>
      {bgNum === '1' ? (
        isEmailModalOpened ? (
          <EmailComp showDashboard={showDashboard} setGlobalEmail={setGlobalEmail} />
        ) : (
          <div className="clearfix flex flex-wrap w-full">
            {/* Left side - can be empty or removed on small screens */}
            <div className="hidden lg:block lg:w-1/2"></div>

            {/* Right side - Main content area */}
            <div className="home-page-welcome w-full lg:w-1/2 text-center px-4 sm:px-8">
              {/* Logo section */}
              <div className="flex justify-center items-center mb-4">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/newlogo.png`}
                  className="company-logo img-fluid w-1/2 md:w-1/3 lg:w-1/2"
                  alt="Newgen"
                  onClick={() => setIsEmailModalOpened(true)}
                />
              </div>

              {/* Title and Description */}
              <h2 className="heading text-3xl md:text-4xl lg:text-5xl font-bold p-2 leading-snug tracking-wide text-orange-400">
                Jingle Jumble
              </h2>
              <h4 className="description text-base md:text-lg lg:text-xl mx-2 sm:mx-5 p-2 leading-relaxed text-white">
                Help Santa unscramble his jumbled insurance puzzle before Christmas Eve!
              </h4>

              {/* Play button */}
              <div className="flex justify-center items-center my-5 p-2">
                <img
                  width={200}
                  src={`${process.env.PUBLIC_URL}/assets/Play-Now-.png`}
                  className="cursor-pointer transition-transform duration-300 ease-in-out hover:scale-110 hover:rotate-6"
                  alt="Play Now"
                  onClick={() => setIsEmailModalOpened(true)}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="w-full h-full">
          {IsOpenHowToPlayModal ? (
            <Modal
              title=""
              open={IsOpenHowToPlayModal}
              onCancel={() => setIsOpenHowToPlayModal(false)}
              footer={null}
              centered
            >
              <div className="">
                {/* <p className="text-lg sm:text-xl font-bold text-red-700">Jingle All the Way to Victory!</p>
                <div className="bg-white p-4 rounded-lg shadow-md text-left text-base sm:text-lg">
                  <p className="font-semibold text-green-600">
                    🎅 Help Santa unscramble his jumbled insurance terms before he takes off on Christmas Eve! Here’s
                    how to play:
                  </p>
                  <ul className="space-y-4 mt-4 text-gray-700">
                    <li>
                      <span className="font-bold text-red-600">Jingle Jumble:</span> Unscramble each Insurance-themed
                      word.
                    </li>
                    <li>
                      <span className="font-bold text-blue-600">Quick as a Rudolph:</span> Type your answers fast-time
                      is ticking!
                    </li>
                    <li>
                      <span className="font-bold text-yellow-600">Claim Your Prize:</span> Submit at least 6 answers to
                      win exciting gifts from Newgen!
                    </li>
                  </ul>
                </div>

                <p className="text-sm sm:text-md font-medium text-gray-800">
                  Join in the festive fun and help Santa save Christmas! 🎁🎉
                </p> */}

                <img src={`${process.env.PUBLIC_URL}/assets/Scroll.png`} alt={'how to play'} className="" />

                <button
                  onClick={() => setIsOpenHowToPlayModal(false)}
                  className="w-full py-2 mt-4 text-md sm:text-lg font-semibold text-white bg-orange-400 rounded-lg hover:bg-orange-700 transition-colors"
                >
                  Let’s Play!
                </button>
              </div>
            </Modal>
          ) : (
            ''
          )}
          <GameCards email={email} setIsOpenHowToPlayModal={setIsOpenHowToPlayModal} />
        </div>
      )}
    </BgLayout>
  );
};

export default Dash;
