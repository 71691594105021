import React, { useState } from 'react';
import { isValidEmail } from '../../../utils/helper';
import { createUserData } from '../../../api/user';
import { notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import HomePageMain from '../../HomePage/HomePageMain';

const EmailComp: React.FC<{ showDashboard: () => void; setGlobalEmail: any }> = ({ showDashboard, setGlobalEmail }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState<string | null>(null);
  const [emailEntered, setEmailEntered] = useState(false);

  const whiteSpinnerIcon = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />;

  const clickedPlay = async () => {
    if (!email || email.length === 0) {
      setError('Email required');
      return;
    }
    if (!isValidEmail(email)) {
      setError('Email is invalid');
      return;
    }
    if (!termsAccepted) {
      setTermsError('You must accept the Terms and Conditions');
      return;
    }
    setLoading(true);
    const payload = {
      email,
    };

    const response = await createUserData(payload);
    if (response.status === 200) {
      setGlobalEmail(email);
      setEmailEntered(true);
      //showDashboard();
      setLoading(false);
    } else {
      setLoading(false);
      console.log(response?.data.error, 'error');
      notification.error({
        message:
          response?.data.error === 'Email already exists'
            ? 'Already Registered with this email.'
            : 'Email Already Exists',
      });
    }
  };

  return (
    <>
      {emailEntered ? (
        <HomePageMain showDashboard={showDashboard} />
      ) : (
        <div className="fixed inset-0 flex items-center justify-center bg-black/40 backdrop-blur-sm p-4 sm:p-8">
          <div className="bg-white rounded-xl p-8 sm:p-10 md:p-12 shadow-2xl transform transition-transform duration-300 hover:scale-105 max-w-sm sm:max-w-md lg:max-w-lg w-full text-center">
            <h1 className="text-2xl sm:text-3xl font-bold text-orange-600 holiday-font animate-bounce">
              🎅 Register on Santa's List!
            </h1>

            <p className="text-gray-700 mt-2 mb-6">Enter your email to help Santa save Christmas!</p>

            <div className="space-y-4">
              <input
                type="email"
                placeholder="Enter Work Email Id"
                value={email}
                onChange={e => {
                  const val = e.target.value;
                  if (val.length === 0) {
                    setError('Email required');
                  } else if (!isValidEmail(val)) {
                    setError('Email is invalid');
                  } else {
                    setError(null);
                  }
                  setEmail(e.target.value);
                }}
                className="w-full p-3 border-2 border-gray-300 rounded-lg focus:border-orange-500 focus:ring-2 focus:ring-orange-500 transition-all duration-300 placeholder-gray-400 text-gray-800"
                required
              />

              {error && <span className="text-sm font-semibold text-red-600">{error}</span>}

              <div className="flex space-x-2">
                <input
                  type="checkbox"
                  id="terms"
                  checked={termsAccepted}
                  onChange={e => {
                    setTermsAccepted(e.target.checked);
                    setTermsError(null);
                  }}
                  className="h-5 w-5 border-gray-300 rounded text-orange-600 focus:ring-2 focus:ring-orange-500"
                />
                <label htmlFor="terms" className="text-sm text-start text-orange-400">
                  Check this box & our Marketing Maestros will keep you updated on the best in Tech for Insurance
                </label>
              </div>
              {termsError && <span className="text-sm font-semibold text-red-600">{termsError}</span>}

              <button
                onClick={clickedPlay}
                disabled={error !== null || !termsAccepted}
                className={`w-full bg-orange-600 text-white py-3 rounded-lg font-bold transform transition-all duration-300 hover:bg-orange-700 hover:scale-105 active:scale-95 hover:shadow-lg ${
                  error !== null || !termsAccepted ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                }`}
              >
                {loading ? <Spin indicator={whiteSpinnerIcon} tip="Loading..." /> : 'Play Now!'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailComp;
