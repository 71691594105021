const correctAnswerPhrases = [
  'Awesome! You got it right!',
  'Spot on! Keep it up!',
  "Great job! That's correct!",
  "You're on fire! Well done!",
  'Impressive! Nailed it!',
  "Fantastic! You're a pro!",
  "Bravo! That's the right answer!",
  'Nice work! You solved it!',
  'Perfect! Keep going!',
  "You're unstoppable! Correct again!",
];

export default correctAnswerPhrases;
