import './App.css';
import ChristmasScrambleGame from './pages/Dashboard'; // Adjust the import as needed
import Dash from './pages/Dashboard/components/Dash';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FinishPage from './pages/Dashboard/components/Finish';
import HowToPlay from './pages/Dashboard/components/HowToPlay';
import HomePageMain from './pages/HomePage/HomePageMain';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Define your routes here */}
          <Route path="/" element={<Dash />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
