import axios from 'axios';
import * as url from './apiUrl';

// const axiosInstance = axios.create()

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const accessToken = localStorage.getItem('accessToken')

//     if (accessToken) {
//       config.headers.Authorization = `Bearer ${accessToken}`
//     } else {
//       console.warn('No access token found')
//     }

//     return config
//   },
//   (error) => {
//     return Promise.reject(error)
//   },
// )

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (
//       error.response &&
//       (error.response.status === 401 || error.response.status === 403)
//     ) {
//       localStorage.clear()
//       window.location.href = '/login'
//     }

//     return Promise.reject(error)
//   },
// )

export const createUserData = async (data: any) => {
  try {
    const response = await axios.post(url.CREATE_USER, data);

    if (response.status >= 200 && response.status < 300) {
      return response;
    }
  } catch (error: any) {
    return error.response;
  }
};
